var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading_exports && !_vm.single)?_c('v-sheet',{staticStyle:{"height":"20vw","width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t('exports.loading_exports')))])],1):_c('v-data-table',{key:_vm.key,attrs:{"sort-by":"created","sort-desc":"","hover":"","hide-default-footer":"","items-per-page":-1,"items":_vm.export_items,"headers":_vm.headers,"loading-text":_vm.$t('exports.loading_exports')},scopedSlots:_vm._u([{key:"item.clipped",fn:function(ref){
var item = ref.item;
return [_c('div',{style:("display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;")},[(!item.clipped)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":15,"width":3,"indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(item.clipped ? '' : _vm.$t('exports.processing'))+" "),(item.clipped)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"item.graphics",fn:function(ref){
var item = ref.item;
return [(item.has_graphics)?_c('div',{style:("display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;")},[(!item.graphics)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":15,"width":3,"indeterminate":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.graphics ? '' : _vm.$t('exports.processing')))]),(item.graphics)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()],1):_c('div',{style:("display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;")},[_c('span',[_vm._v(_vm._s(_vm.$t('exports.no_graphics')))])])]}},{key:"item.play",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",style:(("width: " + (_vm.$vuetify.breakpoint.mobile ? '3em' : '7em'))),on:{"click":function($event){return _vm.playItem(item)}}},[_c('v-icon',[_vm._v(" mdi-play ")])],1)]}},{key:"item.downloadWithoutGraphics",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",staticStyle:{"width":"3em"},attrs:{"disabled":!item.clipped},on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",staticStyle:{"width":"3em"},attrs:{"disabled":!item.clipped || !item.graphics || !item.has_graphics},on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}])}),(_vm.playing)?_c('play-clip',{attrs:{"visible":_vm.playing,"clip_id":_vm.playing_clip_id},on:{"close":function($event){return _vm.closeClip()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }