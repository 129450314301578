<template>
    <transition name="comment">
            <v-card tonal v-if="commentVisible" class="comment" style="max-width: 30vw;">
              <v-card-title>{{ $t("comment.comment") }}</v-card-title>
              <v-card-text>{{
                comment || $t("comment.noComment")
              }}</v-card-text>
              <!-- <v-card-actions
                ><v-btn>{{
                  $t("comment.editComment")
                }}</v-btn></v-card-actions> --> </v-card
            >>
          </transition>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
    export default {
        methods: {
            ...mapActions('comment', ['resetComment'])
        },
        computed: {
            ...mapGetters('comment', ['commentVisible', 'comment']) 
        }
    }
</script>

<style scoped>
.comment {
  padding-inline: 1em;
  padding-bottom: 1em;
  position: absolute;
  bottom: 1em;
  right: 2em;
  z-index: 999;
}
.comment-enter-active {
  animation: comment 50ms ease-out;
}

.comment-leave-active {
  animation: comment 50ms ease-out reverse;
}

@keyframes comment {
  from {
    opacity: 0;
    transform: translateY(7em) translateX(2em) scale(0.9) scaleX(0.2);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateX(0px) scale(1) scaleX(1);
  }
}
</style>