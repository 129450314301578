<template>
    <div style="width: 100%; height: 100%; overflow-y: scroll; padding: 50px 0 80px 0;">
        <div style="max-width: 1200px;" class="mx-auto"  flat>
            <v-card-title>
                <div class="my-3 text-h3">{{ $t('exports.exports') }}</div>
            </v-card-title>
            <v-card-subtitle>{{ $t('exports.subtitle') }}</v-card-subtitle>
            <div>
                <export-items></export-items>
            </div>
        </div>
    </div>
</template>

<script>
    import ExportItems from '../components/exports/ExportItems.vue'
    import {mapActions} from 'vuex'
export default {
    components: {
        ExportItems,
    },
    beforeDestroy() {
       this.clearAll()
    },
    methods: {
        ...mapActions('export', [
            'clearAll'
        ])
    }
}
</script>