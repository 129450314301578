<template>
    <div>
        <v-sheet v-if="loading_exports && !single" style="height: 20vw; width: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular indeterminate></v-progress-circular>
            <span class="ml-3">{{$t('exports.loading_exports')}}</span>
        </v-sheet>
        <v-data-table v-else
            :key="key"  
            sort-by="created"
            sort-desc
            hover
            hide-default-footer
            :items-per-page="-1"
            :items="export_items"
            :headers="headers"
            :loading-text="$t('exports.loading_exports')"
        >
        <template v-slot:item.clipped="{ item }">
            <div :style="`display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;`">
                <v-progress-circular :size="15" :width="3" class="mr-2" indeterminate v-if="!item.clipped"></v-progress-circular>
                {{ item.clipped ? '' : $t('exports.processing') }}
                <v-icon v-if="item.clipped" color="green">mdi-check</v-icon>
            </div>
        </template>
        <template v-slot:item.graphics="{ item }">
            <div v-if="item.has_graphics" :style="`display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;`">
                <v-progress-circular :size="15" :width="3" class="mr-2" indeterminate v-if="!item.graphics"></v-progress-circular>
                <span>{{ item.graphics ? '' : $t('exports.processing') }}</span>
                <v-icon v-if="item.graphics" color="green">mdi-check</v-icon>
            </div>
            <div v-else :style="`display: flex; align-items: center; justify-content: center; width: 100%; height: 70%;`">
                <span>{{ $t('exports.no_graphics') }}</span>
            </div>
        </template>
        <template v-slot:item.play="{ item }">
            <v-btn :style="`width: ${$vuetify.breakpoint.mobile ? '3em' : '7em'}`" class="mx-0" @click="playItem(item)">
                <v-icon>
                    mdi-play
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:item.downloadWithoutGraphics="{ item }">
            <v-btn style="width: 3em" class="mx-0" :disabled="!item.clipped" @click="downloadItem(item)">
                <v-icon>
                    mdi-download
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:item.download="{ item }">
            <v-btn style="width: 3em" class="mx-0" :disabled="!item.clipped || !item.graphics || !item.has_graphics" @click="downloadItem(item)">
                <v-icon>
                    mdi-download
                </v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <play-clip
      v-if="playing"
      :visible="playing"
      :clip_id="playing_clip_id"
      @close="closeClip()"
    ></play-clip>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PlayClip from '../video/PlayClip.vue'
    export default {
        props: ['single'],
        components: {
            PlayClip
        },
        created() {
            if(this.single) {
                return
            }
            this.init()
        },
        mounted() {

            if(this.single) {
                this.interval = setInterval(async () => {
                    await this.fetchPastExport();
                    this.key++
                }, 3000);
            }
            else {
                this.interval = setInterval(async () => {
                    await this.fetchData();
                    this.key++
                }, 3000);
            }
        },

        beforeDestroy() {
            clearInterval(this.interval);
        },

        data: () => ({
            playing: false,
            playing_clip_id: null,
            interval: null,
            key: 0
        }),
        methods: {
            ...mapActions('export', [
                'downloadItem',
                'init',
                'singleInit',
                'clearAll',
                'fetchData',
                'fetchPastExport'
            ]),
            playItem(item) {
                this.playing_clip_id = item.clip_id
                this.playing = true
            },
            closeClip() {
                this.playing = false
                this.playing_clip_id = null
            },
        },
        computed: {
            ...mapGetters('export', [
                'export_items',
                'loading_exports'
            ]),
            headers() {
                let title = this.$t('exports.title')
                let created = this.$t('exports.created')
                let clipped = this.$t('exports.clipped')
                let graphics = this.$t('exports.graphics')
                let preview = this.$t('exports.preview')
                let download_with = this.$t('exports.download_with_g')
                let download_without = this.$t('exports.download_without_g')
                if(this.single) {
                    return [
                {text: title, value: 'title', sortable: false,  width: '30%'},
                {text: created, value: 'created', sortable: false,  width: '10%', align: 'center'},
                {text: clipped, value: 'clipped', sortable: false,  width: '10%', align: 'center'},
                {text: graphics, value: 'graphics', sortable: false,  width: '10%', align: 'center'},
                {text: download_without, value: 'downloadWithoutGraphics', sortable: false, width: '5%', align: 'center'},
                {text: download_with, value: 'download', sortable: false, width: '5%', align: 'center'}
                ]
                }
                return [
                {text: title, value: 'title', sortable: false,  width: '30%'},
                {text: created, value: 'created', sortable: false,  width: '10%', align: 'center'},
                {text: clipped, value: 'clipped', sortable: false,  width: '10%', align: 'center'},
                {text: graphics, value: 'graphics', sortable: false,  width: '10%', align: 'center'},
                {text: preview, value: 'play', sortable: false, width: '10%', align: 'center'},
                {text: download_without, value: 'downloadWithoutGraphics', sortable: false, width: '5%', align: 'center'},
                {text: download_with, value: 'download', sortable: false, width: '5%', align: 'center'}
                ]
            },
        },
    }
</script>