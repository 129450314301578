<template>
  <v-dialog 
    :value="visible"
    @click:outside="$emit('close')"
    fullscreen
  >
    <v-sheet
      :loading="loading"
      style="height: 100vh; display: flex; flex-direction: column; position: relative;"
      :class="landscapePadding"
    >
      <v-btn
        :small="$vuetify.breakpoint.mobile"
        tonal
        v-if="!unclosable"
        absolute
        @click="$emit('close')"
        style="top: 2vh; left: 2vh; z-index: 1000;"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div style="flex-grow: 1 !important; width: 100%; height: 100%; display: flex; justify-content: center; position: relative;">
        <Comment></Comment>
        <!-- <v-img
          v-if="!starttimeLoaded || loading"
          :src="options ? options.poster : ''"
        ></v-img> -->
        <EditorPlayer
          v-if="!loading"
          :options="options"
          v-show="starttimeLoaded"
          :items="items"
          :dimensions="playerCont" 
        ></EditorPlayer>
        <!-- {{options.starttime}}
        {{currentTime}}
        {{accurateCurrentTime}} -->
      </div>
      <div :class="computedBar">
        <editor-bar :times="rawData" v-if="layers && !loading" :class="showLandscape"></editor-bar>
        <ClipBar
          v-else-if="!loading"
          :data="rawData || formDataForBar"
          :buffer="options.buffer"
          :starttime="options.starttime"
          :endtime="options.endtime"
          :hideTimes="true"
          :class="showLandscape"
        ></ClipBar>
        <Bar showCommentBtn="true" :time="time">
          <v-btn v-if="!this.$vuetify.breakpoint.mobile && clip_id && currentTeamId" class="ml-5" @click="$router.push(`/editor?clip_id=${clip_id}&from=${$route.path}`)" x-small>
            <v-icon size="14" class="mr-4">mdi-arrow-right-top</v-icon>
            Open in editor 
          </v-btn>
        </Bar>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import graphics from '@/mixins/graphics.js'
// import Player from '@/components/video/Player'
import Bar from './player/Bar.vue'
import ClipBar from './player/ClipBar.vue'
import EditorPlayer from './ClipEditor/EditorPlayer.vue'
import EditorBar from './ClipEditor/EditorBar.vue'
import Comment from '../comment/Comment.vue'

export default {
  props: ['visible', 'time_id', 'clip_id', 'unclosable', 'manual_time', 'manual_clip'],
  mixins: [ graphics ], 
  components: {
    // Player, 
    Bar, 
    ClipBar,
    EditorPlayer,
    EditorBar,
    Comment
  },
  shortcuts: {
    escape: function() {
      this.$emit('close')
    }
  },
  data: () => ({
    show: false,
    rawData: null,
    options: {
      source: null,
      poster: null,
      starttime: null,
      endtime: null,
      buffer: 0
    },
    loading: true,
    items: null,
    dontClear: false,
    time: null
  }),
  created() {
    if(window.matchMedia("(orientation: landscape)").matches) this.TOGGLE_MOBILE_NAVBAR(false);
    window.addEventListener('orientationchange', () => {
      this.TOGGLE_MOBILE_NAVBAR(window.matchMedia("(orientation: landscape)").matches)
    });
  },
  mounted() {
    if(this.time_id) {
      this.timeById(this.time_id)
        .then(e => {
          this.handleTime(e)
        })
        .catch(e => this.error(e))
    } else if(this.clip_id) {
      this.clipById(this.clip_id)
        .then(e => {
          this.handleClip(e)
        })
        .catch(e => this.error(e))
    } else if(this.manual_time) {
      this.handleTime({data: this.manual_time})
    } else if(this.manual_clip) {
      this.handleClip({data: this.manual_clip})
    }

    window.addEventListener('resize', this.updateScale)
  },
  computed: {
    ...mapGetters('player', [
      'currentTime',
      'accurateCurrentTime'
    ]),
    ...mapGetters('draw', [
      'layers'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    formDataForBar() {
      let data = this.layers?.map(i => {
        return {
          video_time: i.starttime,
          name: ''
        }
      })
      return { data }
    },
    playerCont() {
      let he = window.innerHeight
      let wi = window.innerWidth

      if(wi > he) {
        he = he - 230
        return {
          width: (he * 16 / 9),
          height: he
        }
      } else {
        return {
          width: wi,
          height: (wi * 9 / 16)
        }
      }
    },
    starttimeLoaded() {
      return this.currentTime > ( this.options?.starttime - this.options?.buffer - 1 )
    },
    computedBar() {
      return this.$vuetify.breakpoint.mobile ? 'mobile' : '';
    },
    showLandscape() {
      return this.$vuetify.breakpoint.mobile ? 'mobileShow' : '';
    },
    landscapePadding() {
      return this.$vuetify.breakpoint.mobile ? 'padding' : ''
    },
    // hideRate() {
    //   if (this.$vuetify.breakpoint.mobile && window.matchMedia("(orientation: landscape)").matches) return true;
    //   return false;
    // }
  },
  methods: {
    ...mapActions('comment', [
      'setComment'
    ]),
    ...mapActions('time', [
      'timeById'
    ]),
    ...mapActions('clip', [
      'clipById'
    ]),
    ...mapActions('player', [
      'seek',
      'pause',
      'play',
      'changePlaybackRate'
    ]),
    ...mapActions('noti', [
      'error',
      'success',
      'info'
    ]),
    ...mapActions('draw', [
      'initGraphics',
      'updateScale'
    ]),
    ...mapMutations('draw', [
      'SET_OPTIONS',
      'SET_CLIP'
    ]),
    ...mapMutations([
      'TOGGLE_MOBILE_NAVBAR'
    ]),
    handleTime(e) {
      this.time = e;
      this.setComment(e.data.description);
      const data = e.data?.data
      let first;
      let last;
      data.forEach(d => {
        if(!first || d.video_time < first) first = d.video_time
        if(!last || d.video_time > last) last = d.video_time
      })
      this.rawData = e.data
      this.options.source = e.data.mp4_url || e.data.video_url || e.data.original_url
      this.options.mimeType = e.data.video_type == 'hls' ? 'application/x-mpegURL' : 'video/mp4'
      this.options.poster = e.data.thumb_url
      this.options.starttime = first - 1
      this.options.endtime = last + 1
      this.SET_OPTIONS(this.options)
      this.loading = false
    },
    handleClip(e) {
      this.setComment(e.data.description);
      this.options.source = e.data.mp4_url || e.data.video_url || e.data.original_url
      this.options.mimeType = e.data.video_type == 'hls' ? 'application/x-mpegURL' : 'video/mp4'
      this.options.poster = e.data.thumb_url
      this.options.starttime = e.data.starttime
      this.options.endtime = e.data.endtime

      if(e.data.graphics) {
        this.initGraphics(e.data.graphics)
      }

      if(!e.data.endtime) {
        this.options.starttime = e.data.starttime - 2
        this.options.endtime = e.data.starttime + 5
      }

      this.SET_OPTIONS(this.options)
      this.SET_CLIP(e.data)

      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  @media (orientation: landscape) {
    .mobile {
      position: absolute;
      bottom: 0;
      left: calc(50% - 103.5px);
    }
    .mobileShow {
      display: none;
    }
    .padding {
      padding-bottom: 0px !important;
    }
  }
  .padding {
      padding-bottom: 100px;
    }
</style>