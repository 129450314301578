<template>
  <div
    class="editor-cont"
    :style="`height: 100px; padding: 20px`"

  >
  <div v-dragged="barClicked" @mousedown="barClicked($event)" class="editor-cont-main ruler">
      <div class="editor-cont-cursor" :style="fillStyle"></div>
      <!-- <ul class="ruler-x"><li></li><li></li></ul> -->
      <div class="editor-cont-main-title">
        {{clip ? clip.title : ''}}
      </div>
    </div>


    <!-- SLOWDOWNS -->
    <div
      v-for="item in handled_layers.filter(e => e.type == 'slowdown')"
      :key="item.id"
      class="editor-cont-item editor-cont-item--slowdown"
      :style="`left: ${item.left}px; width: ${item.width}px;`"
      :id="item.id"
    >
      <div 
        v-if="!item.still" 
        class="editor-cont-item-handle editor-cont-item-handle-left"
        v-dragged="leftHandleDragged"
        :id="item.id"
      ></div>
      <v-card
        elevation="3"
        @click="layerClicked({ id: item.id, start_again: false })"
        v-dragged="onDragged"
        :id="item.id"
        class="editor-cont-item-body editor-cont-item-body-slowdown"
        :style="{ backgroundColor: item.still ? '' : '#1A5DA9' }"
      >    
        <div class="editor-cont-item-body-speed">
          {{ item.speed }} x
        </div>
      </v-card>
      <div v-if="!item.still" class="editor-cont-item-handle editor-cont-item-handle-right"
        v-dragged="rightHandleDragged"
        :id="item.id"
      ></div>
    </div>







    <!-- STILLS -->
    <div
      :id="item.id"
      v-for="item in handled_layers.filter(e => e.type != 'slowdown')"
      :key="item.id"
      class="editor-cont-item editor-cont-item--still"
      :style="`left: ${item.left - 15}px;`"
    >
      <div v-if="item.still" class="editor-cont-item-duration">
        {{item.duration || 'ထ'}} s
      </div>
      <v-sheet
        elevation="3"
        @click="layerClicked({ id: item.id, start_again: false })"
        v-dragged="onDragged"
        :id="item.id"
        class="editor-cont-item-body editor-cont-item-body-still"
      > 
        <v-icon color="white" size="20" style="margin: 5px;">
          mdi-draw
        </v-icon>
      </v-sheet>
    </div>


    <!-- {{ form_item_left({starttime: times.data[1].video_time}) }}
    {{times.data.map(e => e.time_from_first)}}
    {{options}} -->
     <!-- TIMES -->
    <div 
      v-for="time in times_data"
      @click="seek(time.video_time)"
      :style="`cursor: pointer; left: ${form_item_left({starttime: time.video_time})}px; position: absolute;`" 
      :key="time.id"
      @click.stop="seek(point.video_time)"
    >
      <div :style="`position: absolute; height: 30px; bottom: 0px; width: 3px; background-color: #888; border-radius: 3px; left: ${form_item_left({starttime: time.video_time})};`">

      </div>
      <div class="ml-2">
        {{secondsToPrettyTimer(time.time_from_first)}}
      </div>
      <div class="ml-2" style="font-size: calc(8px + 0.5vw);">
        {{time.name}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import time from "@/utils/player/time"

export default {
  props: ['immutable', 'times'],
  mixins: [time],
  data: () => ({
    is_dragging: false, // keep
  }),
  mounted() {
    this.REFRESH_EDITOR_CONT_ITEM_WIDTH(document.querySelector('.editor-cont-main')?.offsetWidth)
  },
  computed: {
    ...mapGetters('draw', [
      'handled_layers',
      'options',
      'layers',
      'canvas_dimensions_key',
      'form_item_left'
    ]),
    ...mapGetters('player', [
      'clip',
      'accurateCurrentTime'
    ]),
    times_data() {
      return this.times?.data || []
    },
    fillStyle() {
      if(!this.options) return `left: 0px;`

      this.canvas_dimensions_key;

      const bar = document.querySelector(".editor-cont-main")
      let curr = this.accurateCurrentTime

      if(!bar) return 'left: 20px;'

      const width = bar.offsetWidth
      const progress = curr - this.options.starttime
  
      if(progress < 0) return 'width: 0px;'

      const widthInPixels = Math.min(
        width,
        parseInt(progress / (this.options.endtime - this.options.starttime) * width)
      )

      return `left: ${widthInPixels - 4}px;`
    },
  },
  methods: {
    ...mapActions('draw', [
      'layerClicked'
    ]),
    ...mapActions('player', [
      'seek'
    ]),
    ...mapMutations('draw', [
      'SET_LAYERS',
      'REFRESH_EDITOR_CONT_ITEM_WIDTH'
    ]),
    formItemStartAndEndtime({ left, width }) {
      const w = document.querySelector(".editor-cont-main").offsetWidth

      let starttime = ( 1 - ( ( w - left ) / w )) * ( this.options.endtime - this.options.starttime ) + this.options.starttime
      let endtime = ( 1 - ( ( w - left - width ) / w )) * ( this.options.endtime - this.options.starttime ) + this.options.starttime
 
      return { starttime, endtime }
    },
    barClicked(e) {
      const bar = document.querySelector(".editor-cont-main")
      const width = bar.offsetWidth
      const distance = bar.getBoundingClientRect().left

      const percent = (e.clientX - distance) / width

      this.seek(this.options.starttime + ( ( this.options.endtime - this.options.starttime) * percent ))
    },
    leftHandleDragged({ el, deltaX }) {
      if(this.immutable) return
      if(!deltaX) return
      el = el.closest(`.editor-cont-item`)

      let item = this.handled_layers.find(i => i.id == el.id)

      let i = this.handled_layers.findIndex(i => i.id == el.id)
      let le = item.left
      let wi = item.width

      item = {
        ...item, 
        left: le + deltaX, 
        width: Math.max(wi - deltaX, 10),
        ...this.formItemStartAndEndtime({ width: Math.max(wi - deltaX, 10), left: le + deltaX - 17 })
      }

      el.style.left = le + deltaX + 'px'
      el.style.width = wi - deltaX + 'px'

      let mod = this.handled_layers
      mod[i] = item

      this.SET_LAYERS(mod)
    },
    rightHandleDragged({ el, deltaX }) {
      if(this.immutable) return
      if(!deltaX) return
      el = el.closest(`.editor-cont-item`)

      let item = this.handled_layers.find(i => i.id == el.id)

      let i = this.handled_layers.findIndex(i => i.id == el.id)
      let wi = item.width

      item = {
        ...item, 
        width: Math.max(wi + deltaX, 10),
        ...this.formItemStartAndEndtime({ width: Math.max(wi + deltaX, 10), left: item.left - 17 })
      }

      el.style.width = wi + deltaX + 'px'

      let mod = this.handled_layers
      mod[i] = item

      this.SET_LAYERS(mod)
    },
    onDragged({ el, deltaX, first, last }) {
      if(this.immutable) return
      if(!deltaX) return
      if (first) {
        this.is_dragging = true
        return
      }
      if (last) {
        this.is_dragging = false
        return
      }
      if(!this.layers || !this.layers.length) return

      el = el.closest(".editor-cont-item")

      let item = this.handled_layers.find(i => i.id == el.id)
      let i = this.handled_layers.findIndex(i => i.id == el.id)
      let le = item.left + deltaX
      item = {...item, left: le, ...this.formItemStartAndEndtime({ width: item.width, left: le - 17}) }

      let mod = this.handled_layers
      mod[i] = item

      this.SET_LAYERS(mod)
    },
  }
}
</script>

<style lang="scss" scoped>
  .editor-cont {
    width: 100%;
    padding: 20px;
    height: 30px;
    display: flex; 
    justify-content: flex-end;
    flex-direction: column;
    position: relative;

    &-main {
      background-color: rgb(134, 151, 227);
      height: 30px;
      width: 100%;
      cursor: pointer;
      position: relative;
      border-radius: 4px;

      &-title {
        font-size: 15px;
        color: white;
        margin-left: 10px;
        margin-top: 6px;
      }
    }

    &-cursor {
      z-index: 10;
      top: 0px;
      height: 30px;
      position: absolute;
      width: 4px;
      border-radius: 4px;
      top: 0px;
      background-color: rgb(0, 43, 72);
      transition-duration: 0.03 s;
    }

    &-item {
      position: absolute;
      height: 15px;
      display: flex;
      
      &--still {
        height: 30px !important;
        width: 30px !important;
        flex-grow: 0;
        position: absolute;
        bottom: 20px;
        text-align: center;
      }

      &--slowdown {
        bottom: 5px;
        position: absolute;
      }

      &-duration {
        position: absolute;
        top: -20px;
        font-weight: bold;
        left: 5px;
      }

      &-body {
        flex-grow: 1;
        cursor: pointer;
        border-radius: 0px;

        &-slowdown {
          background: rgb(61,56,149);
          background: linear-gradient(90deg, rgba(61,56,149,1) 0%, rgba(9,9,121,1) 17%, rgba(28,78,161,1) 40%, rgba(0,3,255,1) 60%, rgba(9,0,164,1) 77%, rgba(0,37,255,1) 94%);
        }

        &-still {
          background: rgb(19,18,121);
          background: -moz-radial-gradient(circle, rgba(19,18,121,1) 0%, rgba(35,0,247,1) 47%, rgba(113,0,255,1) 76%);
          background: -webkit-radial-gradient(circle, rgba(19,18,121,1) 0%, rgba(35,0,247,1) 47%, rgba(113,0,255,1) 76%);
          background: radial-gradient(circle, rgba(19,18,121,1) 0%, rgba(35,0,247,1) 47%, rgba(113,0,255,1) 76%);
          border-radius: 5px;
        }

        &-speed {
          color: white;
          font-weight: bold;
          margin-left: 10px;
          font-size: 12px;
          margin-top: -1.5px;
        }
      }

      &-handle {
        width: 5px;
        height: 100%;
        background-color: #001e41;
        cursor: ew-resize;

        &-left {
          border-radius: 4px 0 0 4px;
        }

        &-right {
          border-radius: 0 4px 4px 0;
        }
      }


    }
  }

  /* RULER */
  .ruler {
    --ruler-num-c: #888;
    --ruler-num-fz: 10px;
    --ruler-num-pi: 0.75ch;
    --ruler-unit: 1px;
    --ruler-x: 5;
    --ruler-y: 3;

    --ruler1-bdw: 1px;
    --ruler1-c:  #BBB;
    --ruler1-h: 10px;
    --ruler1-space: 2;

    --ruler2-bdw: 1px;
    --ruler2-c:  #BBB;
    --ruler2-h: 20px;
    --ruler2-space: 50;

    // background-attachment: fixed;
    background-image:
      linear-gradient(90deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0),
      linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
    background-position: 0 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size:
      calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)) var(--ruler1-h),
      calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x)) var(--ruler2-h),
      var(--ruler1-h) calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)),
      var(--ruler2-h) calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  }

  /* Ruler Numbers */
  .ruler-x {
    color: var(--ruler-num-c);
    counter-reset: d 0;
    display: flex;
    font-size: var(--ruler-num-fz);
    line-height: 1;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    // position: fixed;
  }
  .ruler-x, 
    ruler-y {
    height: var(--ruler2-h);
    inset-block-start: 0;
    inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
    opacity: var(--ruler-x);
    width: 100%;
  }

  .ruler-x li {
    align-self: flex-end;
  }
  .ruler-x li {
    counter-increment: d var(--ruler2-space);
    flex: 0 0 calc(var(--ruler-unit) * var(--ruler2-space));
  }
  .ruler-x li::after {
    content: counter(d)"";
    line-height: 1;
    padding-inline-start: var(--ruler-num-pi);
  }
</style>